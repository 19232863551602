'use client';

import { userIsDesignerOrAdmin } from '@lib/auth/auth';
import { IUser } from '@models/User/i-user';
import Script from 'next/script';

interface Props {
  user: IUser | null;
}

export default function ForethoughtInit(
  { user }: Props
) {
  // load Forethought only for logged in users
  if (!user?.jwt) return null;

  return (
    <Script
      strategy="lazyOnload"
      src="https://solve-widget.forethought.ai/embed.js"
      id="forethought-widget-embed-script"
      data-api-key="8dab95cb-af30-435d-bc69-d6af011c9530"
      data-ft-workflow-tag={ userIsDesignerOrAdmin(user) ? 'havenly designers' : 'havenly customer'}
      data-ft-User-Name={ user.name }
      data-ft-User-JWT={user.jwt}
    />
  );
}
